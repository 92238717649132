import React from 'react';
import * as AuscomplyDtos from "../../common/dto/AusComply.dtos";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SearchControl from './SearchControl';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ClearIcon from '@material-ui/icons/Clear';
import LockIcon from '@material-ui/icons/Lock';
import Label from '../common/Label';
import PageMessage from '../common/PageMessage';
import { MulitSelectOption } from '../../common/dto/common';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ControlGroup from '../controls/ControlGroup';
import { Palette } from '../../common/constants/palette';


export interface IMultiSelectControlProps {
    classes: any;
    theme: any;
    current?: AuscomplyDtos.MultiSelectOption[];
    items?: MulitSelectOption[]; // obsolete
    parentIds?: number[];
    searchText?: string;
    selectedTitle?: string;
    clearSearchOnSelect?: boolean;
    commonTitle?: string;
    label?: string;
    isReadonly?: boolean;
    minimumCharactersRequired?: number;
    onSelectItemsChanged?: Function; // obsolete
    onUpdated?: Function;
    canShowAll?: boolean;
    hideTitles?: boolean;
    hideSelectedTitle?: boolean;
    availableTitle?: string;
    wrapControlInCard?: boolean;
    cardTitle?: string;
    controlGroupLabel?: string;
    wrapInControlGroup?: boolean;
    hideIfNoOptions?: boolean;
    labelAbove?: boolean;
    error?: string;
}

interface IMultiSelectControlState {
    search: string;
    showAll: boolean;
    items: AuscomplyDtos.MultiSelectOption[];
}

class MultiSelectControl extends React.PureComponent<IMultiSelectControlProps, IMultiSelectControlState> {
    constructor(props: IMultiSelectControlProps) {
        super(props)
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onSearchChanged = this.onSearchChanged.bind(this);
        this.onShowAll = this.onShowAll.bind(this);
        this.convertOptions = this.convertOptions.bind(this);

        this.state = {
            items: [],
            search: "",
            showAll: false
        };
    }

    componentDidMount() {
        if (this.props.items) {
            this.setState({ items: this.convertOptions(this.props.items), showAll: false });
        } else if (this.props.current) {
            const theItems = this.props.current.map(l => Object.assign({}, l));
            let updated: boolean = false;
            if (this.props.parentIds) {
                const parentIds = this.props.parentIds ? [...this.props.parentIds] : [];
                theItems.forEach(item => {
                    if (item.selected && parentIds.indexOf(item.parentId) === -1) {
                        item.selected = false;
                        updated = true;
                    }
                });
            }
            this.setState({ items: theItems, showAll: false }, () => {
                if (updated && this.props.onUpdated) {
                    this.props.onUpdated(theItems);
                }
            });
        } else {
            this.setState({ items: [], showAll: false })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.items != prevProps.items) {
            if (this.props.items) {
                this.setState({ items: this.convertOptions(this.props.items), showAll: false });
            }
        } else if (this.props.current != prevProps.current) {
            if (this.props.current) {
                const theItems = this.props.current.map(l => Object.assign({}, l));
                let updated: boolean = false;
                if (this.props.parentIds) {
                    const parentIds = this.props.parentIds ? [...this.props.parentIds] : [];
                    theItems.forEach(item => {
                        if (item.selected && parentIds.indexOf(item.parentId) === -1) {
                            item.selected = false;
                            updated = true;
                        }
                    });
                }
                this.setState({ items: theItems }, () => {
                    if (updated && this.props.onUpdated) {
                        this.props.onUpdated(theItems);
                    }
                });
            }
        } else if (this.props.parentIds && this.props.parentIds != prevProps.parentIds && this.props.current) {
            const theItems = this.props.current.map(l => Object.assign({}, l));
            const parentIds = [...this.props.parentIds];
            let updated: boolean = false;
            theItems.forEach(item => {
                if (item.selected && parentIds.indexOf(item.parentId) === -1) {
                    item.selected = false;
                    updated = true;
                }
            });
            this.setState({ items: theItems }, () => {
                if (updated && this.props.onUpdated) {
                    this.props.onUpdated(theItems);
                }
            });
        }
    }

    convertOptions(items: MulitSelectOption[]) {
        return items.map(l => {
            let code = "";
            if (l.code) {
                code = l.code;
            }
            let info = "";
            if (l.info) {
                info = l.info;
            }
            let locked: boolean = false;
            if (l.locked) {
                locked = true;
            }
            let parentId;
            let item: AuscomplyDtos.MultiSelectOption = { ...l, obsolete: false, code, info, locked, parentId };
            return item;
        });
    }

    onSelect(item) {
        const theItems = this.state.items.map(l => Object.assign({}, l));
        theItems.forEach(i => {
            if (i.id == item.id) {
                i.selected = true;
            }
        });
        let search = this.state.search;
        let showAll = this.state.showAll;
        if (this.props.clearSearchOnSelect) {
            search = "";
            showAll = false;
        }
        this.setState({ items: theItems, search, showAll }, () => this.onSelectItemsChanged());
    }

    onRemove(item) {
        if (item.locked) return;

        const theItems = this.state.items.map(l => Object.assign({}, l));
        theItems.forEach(i => {
            if (i.id == item.id) {
                i.selected = false;
            }
        });
        this.setState({ items: theItems }, () => this.onSelectItemsChanged());
    }

    onSearchChanged(text) {
        this.setState({ search: text, showAll: false });
    }

    onSelectItemsChanged() {
        if (this.props.onSelectItemsChanged) {
            let selectedItems = this.state.items.filter(item => { return item.selected; });
            this.props.onSelectItemsChanged(selectedItems);
        }
        if (this.props.onUpdated) {
            const theItems = this.state.items.map(l => Object.assign({}, l));
            this.props.onUpdated(theItems);
        }
    }

    onShowAll() {
        this.setState({ showAll: !this.state.showAll });
    }

    render() {
        const { classes, theme } = this.props;

        if (!this.state.items) {
            if (this.props.hideIfNoOptions) {
                return <></>;
            }
            return (
                <PageMessage text={"Items are missing"} />
            );
        }
        const parentIds = this.props.parentIds ? [...this.props.parentIds] : [];
        let items = [...this.state.items].filter(f => parentIds.length == 0 || parentIds.indexOf(f.parentId) > -1);

        if (this.props.hideIfNoOptions && items.length === 0) {
            return <></>;
        }

        let suffix = "";
        if (this.props.label) {
            suffix = " " + this.props.label;
        }

        let selectedTitle = "Selected" + suffix;
        if (this.props.hideTitles) {
            selectedTitle = suffix;
        }

        if (this.props.isReadonly) {
            let selected = items.filter(item => { return item.selected; });
            let display = selected.length === 0 ? <div key={"selected-msi-nothing"} style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                padding: 0,
                margin: '4px 0'
            }}><p style={{
                margin: '4px',
                flex: 1,
                color: Palette.PrimaryDisabledText,
                textAlign: 'left'
            }}>Nothing selected</p></div> : items.filter(item => { return item.selected; }).map((item, index) => (
                <div key={"selected-msi-" + item.id} style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    borderBottomColor: theme.custom.colors.border,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    borderRadius: 0,
                    padding: 0,
                    margin: '4px 0'
                }}>
                    <p style={{
                        margin: '4px',
                        flex: 1,
                        color: 'white',
                        textAlign: 'left'
                    }}>
                        {item.name}
                    </p>
                </div>
            ));

            if (this.props.wrapControlInCard) {
                return <ControlGroup
                    labelAbove={this.props.wrapInControlGroup != undefined ? !this.props.wrapInControlGroup : true}
                    text={" "}>
                    <CardContainer
                        title={this.props.cardTitle}
                        style={{ paddingTop: 0 }}>
                        <CardContent style={{ paddingTop: 0 }}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}><div style={{
                                    textAlign: 'center',
                                    padding: '16px 20px 10px',
                                    margin: '0 -20px -10px',
                                    backgroundColor: '#242424'
                                }}>
                                    {display}
                                </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardContainer>
                </ControlGroup>
            }

            return (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <div style={{
                            textAlign: 'center',
                            padding: '16px 20px 10px',
                            margin: '0 -20px 0',
                            backgroundColor: '#242424'
                        }}>
                            {display}
                        </div>
                    </Grid>
                </Grid>
            );

        }

        let selectedItems = items.filter(item => { return item.selected; }).map((item, index) => (
            <div key={"selected-msi-" + item.id} style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                borderBottomColor: theme.custom.colors.border,
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderRadius: 0,
                padding: 0,
                margin: '4px 0'
            }}>
                <p style={{
                    margin: '4px',
                    flex: 1,
                    color: 'white',
                    textAlign: 'left',
                    position: 'relative'
                }}
                    onClick={() => this.onRemove(item)}>
                    {item.name} <span style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        color: '#aaa'
                    }}>{item.info}</span>
                </p>
                <Divider style={{
                    width: 1,
                    height: 28,
                    margin: 4,
                    color: theme.custom.colors.border
                }} />
                <IconButton style={{ padding: '6px', color: theme.custom.colors.error }} aria-label="Remove" onClick={() => this.onRemove(item)}>
                    {item.locked ? <LockIcon style={{ fontSize: '1em', color: 'white' }} /> : <ClearIcon style={{ fontSize: '1.3em' }} />}
                </IconButton>
            </div>
        ))



        let selected = (
            <div style={{
                textAlign: 'center',
                padding: '16px 20px 10px',
                margin: '0 -20px 0',
                backgroundColor: '#242424'
            }}>
                {!this.props.hideSelectedTitle && (
                    <Label text={selectedTitle}
                        style={{
                            textTransform: 'uppercase',
                            color: 'white',
                            fontWeight: 'bold'
                        }} />
                )}
                {(!selectedItems || selectedItems.length == 0) && (
                    <p style={{ color: '#818183', borderBottom: '1px solid #818183', padding: '0 10px 10px' }}>Nothing Selected</p>
                )}
                {(selectedItems && selectedItems.length > 0) && (
                    <>{selectedItems} </>
                )}
            </div>
        );

        let commonItems = items.filter(item => { return !item.selected && item.isCommon; }).map((item, index) => (
            <div key={"common-msi-" + item.id} style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                borderBottomColor: theme.custom.colors.border,
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderRadius: 0,
                padding: 0,
                margin: '4px 0'
            }}>
                <p style={{
                    margin: '4px',
                    flex: 1,
                    color: 'white',
                    position: 'relative'
                }}
                    onClick={() => this.onSelect(item)}>
                    {item.name} <span style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        color: '#aaa'
                    }}>{item.info}</span>
                </p>
                <Divider style={{
                    width: 1,
                    height: 28,
                    margin: 4
                }} />
                <IconButton style={{ padding: '6px', color: theme.custom.colors.primary }} aria-label="Select" onClick={() => this.onSelect(item)}>
                    <AddIcon style={{ fontSize: '1.3em' }} />
                </IconButton>
            </div>
        ))

        let common;
        if (commonItems && commonItems.length > 0) {
            if (this.props.hideTitles) {
                common = (
                    <>
                        {commonItems}
                    </>
                )
            } else {
                common = (
                    <>
                        <Label text={this.props.commonTitle || ("Common" + suffix)} style={{ textTransform: 'uppercase', margin: '15px 0 0 0', display: 'block' }} />
                        {commonItems}
                    </>
                )
            }

        }

        let minSearchLength = this.props.minimumCharactersRequired || -1;
        let totalAvailable = items.filter(item => { return !item.selected && !item.isCommon; }).length;
        let availableItems = items.filter(item => {

            if (this.state.showAll) {
                return !item.selected;
            }

            if (!this.state.search && minSearchLength > -1) {
                return false;
            }

            if (this.state.search && this.state.search.length < minSearchLength) {
                return false;
            }
            if (this.state.search) {
                if (item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) < 0) {
                    if (item.code) {
                        if (item.code.toLowerCase().indexOf(this.state.search.toLowerCase()) < 0) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            }
            return !item.selected && !item.isCommon;
        }).map((item, index) => (
            <div key={"available-msi-" + item.id} style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                borderBottomColor: theme.custom.colors.border,
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderRadius: 0,
                padding: 0,
                margin: '4px 0'
            }}>
                <p style={{
                    margin: '4px',
                    flex: 1,
                    color: 'white',
                    position: 'relative'
                }}
                    onClick={() => this.onSelect(item)}
                >
                    {item.name} <span style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        color: '#aaa'
                    }}>{item.info}</span>
                </p>
                <Divider style={{
                    width: 1,
                    height: '20px',
                    margin: 4
                }} />
                <IconButton style={{ padding: '6px', color: theme.custom.colors.primary }} aria-label="Select" onClick={() => this.onSelect(item)}>
                    <AddIcon style={{ fontSize: '1.3em' }} />
                </IconButton>
            </div>
        ))

        let available;
        if (availableItems && (availableItems.length > 0 || totalAvailable > 0)) {
            available = (
                <>
                    <Label text={this.props.availableTitle || ("Available" + suffix)} style={{ textTransform: 'uppercase', margin: '15px 0 0 0', display: 'block' }} />
                    <SearchControl
                        value={this.state.search}
                        searchText={this.props.searchText}
                        onChange={this.onSearchChanged}
                        showAll={this.props.canShowAll}
                        onShowAll={this.onShowAll}
                    />
                    {(totalAvailable > 0 && availableItems.length == 0 && this.state.search && this.state.search.length >= minSearchLength) && (
                        <div style={{ marginTop: '10px' }}>
                            <PageMessage text={"Nothing found"} />
                        </div>
                    )}
                    {availableItems.length > 0 && (
                        <>
                            {availableItems}
                        </>
                    )}

                </>
            )
        }

        if (!this.props.wrapControlInCard && this.props.wrapInControlGroup) {
            return <ControlGroup
                labelAbove={this.props.labelAbove}
                errorText={this.props.error}
                text={this.props.controlGroupLabel}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {selected}
                                {common}
                                {available}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ControlGroup>
        }

        if (this.props.wrapControlInCard && this.props.wrapInControlGroup) {
            return <ControlGroup
                labelAbove={this.props.wrapInControlGroup != undefined ? !this.props.wrapInControlGroup : true}
                errorText={this.props.error}
                text={" "}>
                <CardContainer
                    title={this.props.cardTitle}
                    style={{ paddingTop: 0 }}>
                    <CardContent style={{ paddingTop: 0 }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        {selected}
                                        {common}
                                        {available}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </ControlGroup>
        }

        if (this.props.wrapControlInCard) {
            return <CardContainer
                title={this.props.cardTitle}
                errorText={this.props.error}
                style={{ paddingTop: 0 }}>
                <CardContent style={{ paddingTop: 0 }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {selected}
                                    {common}
                                    {available}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardContainer>
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {selected}
                    {common}
                    {available}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(MultiSelectControl);