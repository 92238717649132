import React, { Component } from 'react';
import * as AuscomplyDtos from "../../common/dto/AusComply.dtos";
import ControlGroup from '../controls/ControlGroup';
import MultiSelectControl from '../controls/MutiSelectControl';
import { MulitSelectOption } from '../../common/dto/common';
import RadioButtonControl from '../controls/RadioButtonControl';
import { RadioOption } from '../../common/dto/common';
import SearchDateControlGroup from '../controls/SearchDateControlGroup';
import TextControlGroup from '../controls/TextControlGroup';
import View from '../common/View';

export interface IIncidentsFilterEventsProps {
    incidentHistoryFilter: AuscomplyDtos.IncidentHistoryFilter;
    onUpdate: Function;
    onSearch: Function;
}

interface IIncidentsFilterEventsState {
    reportingPeriods: AuscomplyDtos.ReportingPeriodSelection[];
}

export default class IncidentsFilterEvents extends Component<IIncidentsFilterEventsProps, IIncidentsFilterEventsState> {
    constructor(props: IIncidentsFilterEventsProps) {
        super(props)
        this.state = {
            reportingPeriods: []
        };
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount(): void {
        if (this.props.incidentHistoryFilter) {
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.incidentHistoryFilter &&
            (this.hasChangedHash(this.props.incidentHistoryFilter) != this.hasChangedHash(prevProps.incidentHistoryFilter))) {
        }
    }

    hasChangedHash(incidentHistoryFilter: AuscomplyDtos.IncidentHistoryFilter) {
        let hash = "";
        if (incidentHistoryFilter && incidentHistoryFilter.venueEvents) {
            incidentHistoryFilter.venueEvents.forEach((item, index) => {
                hash += "#ev-" + item.id.toString() + item.selected ? "-selected" : "";
            });
        }
        return hash;
    }

    onValueChanged(fieldName: string, value: any) {
        let incidentHistoryFilter = { ...this.props.incidentHistoryFilter };
        if (fieldName == "filterByOptions") {
            incidentHistoryFilter.byEventReportingPeriods = value == "1";
        } else {
            incidentHistoryFilter[fieldName] = value;
            if (fieldName === "venueEvents") {
                // remove any reporting periods selected for these events
            }
        }
        incidentHistoryFilter.paging.page = 1;
        this.props.onUpdate(incidentHistoryFilter);
    }

    render() {
        let filterByOptions: RadioOption[] = [
            { id: "1", name: "Reporting Period" },
            { id: "2", name: "Date" }
        ];
        let filterByOption = this.props.incidentHistoryFilter.byEventReportingPeriods ? "1" : "2";
        let filterByOptionControl = filterByOption == "1" ?
            <>
                <MultiSelectControl
                    wrapControlInCard={false}
                    wrapInControlGroup={true}
                    labelAbove={true}
                    controlGroupLabel='Reporting Periods'
                    parentIds={this.props.incidentHistoryFilter.venueEvents.filter(f => f.selected).map((item, index) => item.id)}
                    current={this.props.incidentHistoryFilter.eventReportingPeriods}
                    minimumCharactersRequired={3}
                    canShowAll={true}
                    hideTitles={true}
                    searchText={"Type at least three letters..."}
                    onUpdated={values => this.onValueChanged("eventReportingPeriods", values)} />
            </>
            : <>
                <SearchDateControlGroup
                    text="Time Period"
                    rightText={"From"}
                    labelAbove={true}
                    date={this.props.incidentHistoryFilter.from}
                    onEnter={() => this.props.onSearch()}
                    onChange={value => this.onValueChanged("from", value)}
                    defaultTime={"00:00"} />
                <SearchDateControlGroup
                    rightText="To"
                    labelAbove={true}
                    date={this.props.incidentHistoryFilter.to}
                    onEnter={() => this.props.onSearch()}
                    onChange={value => this.onValueChanged("to", value)}
                    defaultTime={"23:59"} />
            </>;

        return (
            <View style={{ marginTop: '10px' }}>
                <MultiSelectControl
                    wrapControlInCard={false}
                    wrapInControlGroup={true}
                    labelAbove={true}
                    controlGroupLabel='Events'
                    current={this.props.incidentHistoryFilter.venueEvents}
                    minimumCharactersRequired={3}
                    canShowAll={true}
                    hideTitles={true}
                    searchText={"Type at least three letters..."}
                    onUpdated={values => this.onValueChanged("venueEvents", values)} />
                <RadioButtonControl
                    selected={filterByOption}
                    options={filterByOptions}
                    name={"filterByOptions"}
                    onChange={(value) => this.onValueChanged("filterByOptions", value.value)} />
                {filterByOptionControl}
            </View>
        );
    }
}