import React from 'react';
import View from '../common/View';
import Grid from '@material-ui/core/Grid';
import { PickerItem } from '../common/PickerItem';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import { RadioOption } from '../../common/dto/common';
import RadioControl from '../controls/RadioControl';
import RadioButtonControl from '../controls/RadioButtonControl';
import CheckboxControl from '../controls/CheckboxControl'
import SelectControlGroup from '../controls/SelectControlGroup';
import SearchDateControlGroup from '../controls/SearchDateControlGroup';
import TextControlGroup from '../controls/TextControlGroup';
import TransparentButton from '../controls/TransparentButton';
import LayoutForm from '../layout/LayoutForm';
import DefaultButton from '../controls/DefaultButton';
import IncidentsFilterIncidentTypes from './IncidentsFilterIncidentTypes';
import IncidentsFilterFunctionalAreas from './IncidentsFilterFunctionalAreas';
import IncidentsFilterEvents from './IncidentsFilterEvents';

export interface IIncidentsFilterProps {
    filter: AusComplyDTOs.IncidentHistoryFilter;
    onUpdateFilter: Function;
    onReset: Function;
    onSearch: Function;
}

interface IIncidentsFilterState {
    filter: AusComplyDTOs.IncidentHistoryFilter;
}

export default class IncidentsFilter extends React.Component<IIncidentsFilterProps, IIncidentsFilterState> {
    constructor(props: IIncidentsFilterProps) {
        super(props);
        this.onFilterByOptionChanged = this.onFilterByOptionChanged.bind(this);
        this.onSelectedReportingPeriodChanged = this.onSelectedReportingPeriodChanged.bind(this);
        this.onFromChanged = this.onFromChanged.bind(this);
        this.onToChanged = this.onToChanged.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.legislatedChanged = this.legislatedChanged.bind(this);
        this.unapprovedOnlyChanged = this.unapprovedOnlyChanged.bind(this);
        this.includeIDChecksChanged = this.includeIDChecksChanged.bind(this);
        this.withPhotoChanged = this.withPhotoChanged.bind(this);

        this.state = {
            filter: new AusComplyDTOs.IncidentHistoryFilter()
        };
    }

    componentDidMount() {
        this.setState({ filter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    onFilterByOptionChanged(value) {
        let filter = { ... this.state.filter };
        filter.byReportingPeriod = value.value == "1";
        filter.byEvent = value.value == "3";
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onSelectedReportingPeriodChanged(value: any) {
        let filter = { ... this.state.filter };
        filter.reportingPeriodId = parseInt(value.toString());
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onFromChanged(value) {
        let filter = { ...this.state.filter };
        filter.from = value;
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onToChanged(value) {
        let filter = { ...this.state.filter };
        filter.to = value;
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onSearchChange(value) {
        let filter = { ... this.state.filter };
        filter.search = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    unapprovedOnlyChanged(value) {
        let filter = { ...this.state.filter };
        filter.unapprovedOnly = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    legislatedChanged(value) {
        let filter = { ...this.state.filter };
        filter.legislated = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    includeIDChecksChanged(value) {
        let filter = { ...this.state.filter };
        filter.includeIDChecks = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    withPhotoChanged(value) {
        let filter = { ...this.state.filter };
        filter.withPhoto = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }
        let self = this;
        let filter = this.state.filter;
        let canFilterReportingPeriod = this.state.filter.canFilterReportingPeriod;
        let canFilterAdvanced = this.state.filter.canFilterAdvanced;
        let canViewIDChecks = this.state.filter.canViewIDChecks;

        // filter for users show can only toggle basic options
        if ((!canFilterAdvanced && !canFilterReportingPeriod)) {
            return (
                <View>
                    <CheckboxControl text="Unapproved Only" defaultValue={this.state.filter.unapprovedOnly} onChanged={this.unapprovedOnlyChanged} />
                </View>
            );
        }

        let reportingPeriods;
        if (this.state.filter.reportingPeriods) {
            reportingPeriods = [{ venueReportingPeriodID: 0, reportingPeriodDisplayString: "" }, ...this.state.filter.reportingPeriods].map((item, index) => (
                <PickerItem key={item.venueReportingPeriodID} label={item.reportingPeriodDisplayString} value={item.venueReportingPeriodID} />
            ));
        }

        let reportingPeriodSelector = (
            <SelectControlGroup
                text="Reporting period"
                labelAbove={true}
                onChange={this.onSelectedReportingPeriodChanged}
                defaultValue={this.state.filter.reportingPeriodId}
                items={reportingPeriods} />
        );

        let resetButton = (
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TransparentButton text={"Reset"} onClick={this.onResetSearch} />
                </Grid>
                <Grid item xs={6}>
                    <DefaultButton text={"Search"} onClick={() => this.props.onSearch()} />
                </Grid>
            </Grid>
        );

        let incidentTypes = this.state.filter.forEntryPage !== "" && this.state.filter.forEntryPage !== undefined ? <></> : (
            <IncidentsFilterIncidentTypes incidentHistoryFilter={this.state.filter} onUpdate={filter =>
                self.setState({ filter }, () => {
                    self.props.onUpdateFilter(this.state.filter);
                })} />);


        let unapprovedAndLegislated = this.state.filter.forEntryPage !== "" && this.state.filter.forEntryPage !== undefined ?
            <></> :
            <View style={{ margin: '20px 0' }}>
                <CheckboxControl text="Unapproved Only" defaultValue={this.state.filter.unapprovedOnly} onChanged={this.unapprovedOnlyChanged} />
                <CheckboxControl text="Legislated" defaultValue={this.state.filter.legislated} onChanged={this.legislatedChanged} />
                <CheckboxControl text="Show Photo" defaultValue={this.state.filter.withPhoto} onChanged={this.withPhotoChanged} />
            </View>
            ;

        // filter for when only the reporting period can be changed
        if (!canFilterAdvanced && canFilterReportingPeriod) {
            return (
                <LayoutForm>
                    {reportingPeriodSelector}
                    {incidentTypes}
                    {unapprovedAndLegislated}
                    {resetButton}
                </LayoutForm>
            );
        }

        let filterByOptions: RadioOption[] = [
            { id: "1", name: "Reporting Period" },
            { id: "2", name: "History" }
        ];
        if (filter.canViewEvents) {
            filterByOptions.push({ id: "3", name: "Events" });
        }
        let filterByOption = this.state.filter.byEvent ? "3" : this.state.filter.byReportingPeriod ? "1" : "2";
        /*
        let filterOptionDisplay = (
            <RadioControl
                selected={filterByOption}
                options={filterByOptions}
                name={"filterByOptions"}
                onChange={this.onFilterByOptionChanged} />
        );
        */
        let filterOptionDisplay = (<RadioButtonControl
            selected={filterByOption}
            options={filterByOptions}
            name={"filterByOptions"}
            onChange={(value) => this.onFilterByOptionChanged(value)} />);

        // advanced filters
        if (filterByOption == "1") {
            return (
                <>
                    {filterOptionDisplay}
                    {reportingPeriodSelector}
                    {incidentTypes}
                    {unapprovedAndLegislated}
                </>
            );
        } if (filterByOption == "3") {
            return (<>
                {filterOptionDisplay}
                <IncidentsFilterEvents 
                    incidentHistoryFilter={this.state.filter}
                    onSearch={() => this.props.onSearch()}
                    onUpdate={filter =>
                        self.setState({ filter }, () => {
                            self.props.onUpdateFilter(this.state.filter);
                        })}
                />
                <TextControlGroup text={"Search"}
                    labelAbove={true}
                    defaultValue={this.state.filter.search}
                    onSubmit={value => {
                        this.onSearchChange(value);
                        this.props.onSearch();
                    }}
                    onChange={this.onSearchChange} />
                {incidentTypes}
                <IncidentsFilterFunctionalAreas
                    incidentHistoryFilter={this.state.filter}
                    onUpdate={filter =>
                        self.setState({ filter }, () => {
                            self.props.onUpdateFilter(this.state.filter);
                        })} />
                {unapprovedAndLegislated}
            </>);
        } else {
            return (
                <>
                    {filterOptionDisplay}
                    <SearchDateControlGroup
                        text="Time Period"
                        rightText={"From"}
                        labelAbove={true}
                        date={this.state.filter.from}
                        onEnter={() => this.props.onSearch()}
                        onChange={this.onFromChanged}
                        defaultTime={"00:00"} />
                    <SearchDateControlGroup
                        rightText="To"
                        labelAbove={true}
                        date={this.state.filter.to}
                        onEnter={() => this.props.onSearch()}
                        onChange={this.onToChanged}
                        defaultTime={"23:59"} />
                    <TextControlGroup text={"Search"}
                        labelAbove={true}
                        defaultValue={this.state.filter.search}
                        onSubmit={value => {
                            this.onSearchChange(value);
                            this.props.onSearch();
                        }}
                        onChange={this.onSearchChange} />
                    {incidentTypes}
                    <IncidentsFilterFunctionalAreas
                        incidentHistoryFilter={this.state.filter}
                        onUpdate={filter =>
                            self.setState({ filter }, () => {
                                self.props.onUpdateFilter(this.state.filter);
                            })} />
                    {unapprovedAndLegislated}
                </>
            );
        }
    }
}